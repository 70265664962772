.field_card {
	background-color: var(--grey-scale-1);
	padding: 8px 16px;
	border-radius: 8px;
	width: 100%;
	height: 100%;
}

.info_icon {
	font-size: 18;
	color: var(--grey-scale-5);
}
