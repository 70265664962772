@import './assets/fonts/Jost/font-jost';
@import './assets/print.css';
@import './styles/antd-overrides.css';
@import './styles/common.css';

:root {
	--font-color: #161616;
	--border-color: #d9d9d9;
	--background-color: #ffffff;
	--info-color: #808080;
	--error-text-color: #b80101;
	--error-background-color: #ffd1d1;

	/* GREY SCALE */
	--grey-scale-1: #fafafa;
	--grey-scale-2: #e1e1e1;
	--grey-scale-3: #c8c8c8;
	--grey-scale-4: #98a2b3;
	--grey-scale-5: #667085;
	--grey-scale-8: #1d2939;
}

html,
body {
	overflow: hidden;
}

html {
	font-size: 16px;
	box-sizing: border-box;
	color: var(--font-color);
}

*,
*:before,
*:after {
	box-sizing: inherit;
}

body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
ol,
ul {
	margin: 0;
	padding: 0;
	outline: 0;
	font-weight: normal;
}

ol,
ul {
	list-style: none;
}

img {
	max-width: 100%;
	height: auto;
}

a {
	color: inherit;
	text-decoration: inherit;
}

body {
	font-family: 'Jost', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
		'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.rtl {
	direction: rtl;
}

.flag_img {
	width: 16px;
	height: 16px;
	max-width: unset;
}

/* *** START HAMMERING *** */
/* Link button on NewOrdersFetcher */
.link_button_no_width .link_button {
	width: unset;
	color: var(--ant-primary-color);
}
.link_button_no_width .link_button:hover {
	color: var(--ant-primary-color-hover);
}
/* *** STOP HAMMERING *** */

.link_button {
	width: 100%;
	min-width: 0;
	text-align: start;
	display: inline-flex;
	align-items: center;
}
.link_button span:last-of-type {
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}

.ant-btn .anticon {
	transition: all 0.4s;
}
.anticon.rotate {
	transform: rotate(180deg);
}
