.copy_button:global(.ant-btn),
.copy_button:global(.ant-btn):hover {
	color: var(--ant-primary-color);
	padding: 0;
	max-width: 100%;
	min-width: 0;
	display: inline-flex;
	align-items: center;
	gap: 8px;
}

.copy_button:global(.ant-btn).reverted {
	flex-flow: row-reverse;
}

.copy_button:global(.ant-btn) :global(.anticon) {
	flex-shrink: 0;
}

.copy_button:global(.ant-btn > .anticon + span) {
	margin: 0;
}

.copy_button:global(.ant-btn) span:last-of-type {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}
