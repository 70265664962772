.login_wrapper {
	display: flex;
	align-items: center;
	flex-direction: column;
	margin-top: 100px;
}

.logo {
	width: 250px;
	height: inherit;
	pointer-events: none;
}

.auth_box {
	padding: 20px;
	margin-top: 30px;
	background-color: white;

	border: none;
	border-radius: 6px;
	box-shadow: 0 0 6px 0 rgb(43 43 43 / 10%), 0 11px 6px -7px rgb(43 43 43 / 10%);
}

.auth_box h3 {
	user-select: none;
	text-align: center;
	margin-bottom: 24px;
	font-weight: normal;
}

.auth_form {
	width: 350px;
	max-width: 350px;
}
