/* Print Order Layout
+--------------+--------------+----------------+
|              |     Logo     |                |
+--------------+--------------+----------------+
|                 Description                  |
+--------------+--------------+----------------+
|                Product List                  |
+--------------+--------------+----------------+
| Summary                       Summary Values |
+--------------+--------------+----------------+
|              |              | Final summary  |
+--------------+--------------+----------------+
|              | pay method   |                |
|              | n_items      |                |
+--------------+--------------+----------------+
 */

:root {
	--grey-background: #e6e9ed;
	--grey: #868c92;
}

/* HEADER  */
.print_header_space.order_print_figma {
	margin-top: 50px;
	height: fit-content;
}

.order_print_header_logo_container {
	display: flex;
	justify-content: center;
	margin-bottom: 48px;
}

.order_print_logo {
	max-width: 200px;
	max-height: 80px;
}

/* DETAILS */
.order_print_details {
	border: 1px solid #f0f1f4;
	color: var(--font-color);

	margin-bottom: 24px;
	font-size: 14px;

	padding: 1rem 1.2rem;
}

.order_print_details .label {
	font-weight: 600;
	font-size: 14px;
}

.order_print_details .label::after {
	content: ':';
	padding-left: 0.1em;
}

.order_product_details {
	font-size: 16px;
	line-height: 20px;
}
.order_product_name {
	font-weight: 600;
}

.order_final_summary,
.order_summary,
.order_print_product_table_quantity_column,
.order_print_product_table_price_column,
.order_product_name {
	font-size: 17px;
	line-height: 2em;
}

[data-currency]::before {
	content: attr(data-currency) ' ';
}

/* PRODUCT LIST */
.order_print_product_list {
	width: 100%;
}

.order_print_product_list th,
.order_print_product_list td {
	padding: 10px 12px;
	vertical-align: top;
}

.order_print_product_list th {
	font-weight: 600;
	background-color: var(--grey-background);
	border: 4px solid white;
}
.order_print_product_list th {
	width: 22%;
}
.order_print_product_list th:first-child {
	width: 60%;
}

/* INVOICE SUMMARY */
.order_final_summary,
.order_summary {
	text-align: right;
}

.order_summary_values {
	margin: 0 66px;
}

.order_final_summary {
	min-width: 200px;
}

.order_final_summary .order_final_summary_total_cost {
	font-weight: 600;
	font-size: 22px;
	color: var(--font-color);
}

.order_final_summary .order_final_summary_tax_description {
	font-weight: 400;
	font-size: 13px;
	line-height: 1em;
	color: var(--grey);
}

/* OTHER SECTIONS */
.order_additional_info {
	padding: 0 18px;
	font-size: 12px;
	line-height: 14px;
}

/* UTILITY STYLES */
.divider_title {
	text-transform: uppercase;
}

.text_number {
	direction: ltr;
	display: inline-block;
	white-space: nowrap;
}

.last_info {
	text-align: center;
	padding-top: 20px;
}
