.main_container {
	height: 100%;
	overflow-y: auto;
}

.payment_success_card {
	border-color: #b7eb8f; /* success tag border-color */
}

.payment_warning_card {
	border-color: #ffe58f; /* warning tag border-color */
}

.payment_invoice_button {
	padding-top: 1rem;
}
