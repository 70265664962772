.tabs_container {
	width: 100%;
	display: flex;
}

.tabs_container:global(.ant-tabs .ant-tabs-tab) {
	flex: 1;
	align-items: flex-start;
}

.tabs_container:global(.ant-tabs > .ant-tabs-nav .ant-tabs-nav-list) {
	width: 100%;
}

.tabs_container:global(.ant-tabs > .ant-tabs-nav .ant-tabs-nav-operations) {
	display: none;
}

.tabs_container :global(.ant-tabs-tab) {
	padding: 0 24px 24px 24px;
}
