.incentive_type {
	height: 100%;
	cursor: pointer;
}

.incentive_type_selected:global(.ant-card) {
	background-color: var(--ant-primary-1);
	border: 1px solid var(--ant-primary-color);
}

.incentive_type.incentive_type_disabled {
	cursor: unset;
}

.check_icon {
	padding: 8px;
	background-color: var(--grey-scale-2);
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;

	transition: background-color 0.5s ease;
}

.check_icon.active {
	background-color: var(--ant-primary-2);
}

/* ----------------------------------------------
 * Generated by Animista on 2023-7-25 11:24:25
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

.fade_in_fwd {
	-webkit-animation: fade_in_fwd 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) both;
	animation: fade_in_fwd 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

/**
 * ----------------------------------------
 * animation fade-in-fwd
 * ----------------------------------------
 */
@-webkit-keyframes fade_in_fwd {
	0% {
		-webkit-transform: translateZ(-80px);
		transform: translateZ(-80px);
		opacity: 0;
	}
	100% {
		-webkit-transform: translateZ(0);
		transform: translateZ(0);
		opacity: 1;
	}
}

@keyframes fade_in_fwd {
	0% {
		-webkit-transform: translateZ(-80px);
		transform: translateZ(-80px);
		opacity: 0;
	}
	100% {
		-webkit-transform: translateZ(0);
		transform: translateZ(0);
		opacity: 1;
	}
}

/* ----------------------------------------------
 * Generated by Animista on 2023-7-25 13:53:30
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

.fade_out {
	-webkit-animation: fade_out 0.3s ease-out both;
	animation: fade_out 0.3s ease-out both;
}

/**
 * ----------------------------------------
 * animation fade-out
 * ----------------------------------------
 */
@-webkit-keyframes fade_out {
	0% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}

@keyframes fade_out {
	0% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}
