.recommended_card {
	position: relative;
	padding-top: 13px;
	width: 100%;
}

.recommended_tag {
	position: absolute;
	left: -8px;
	top: 0;
	z-index: 1;

	content: '';
	padding: 4px 12px;
	border-radius: 4px;
	border-bottom-left-radius: 0;
	color: #fff;
	font-size: 12px;

	display: flex;
	align-items: center;

	gap: 8px;
}

.recommended_tag::after {
	position: absolute;
	content: '';
	border-bottom: 8px solid transparent;
	top: 27px;
	left: 0;

	border-right: 8px solid #b70064;
}

.recommendation_icon {
	width: 32px;
	height: 32px;
	border-radius: 50%;
}

.title {
	display: flex;
	align-items: center;
	gap: 8px;
	font-size: 16px;
	min-height: 32px;
}

.card_content {
	display: flex;
	flex-direction: column;
	height: 100%;
	gap: 16px;
}

.card_content > :nth-child(2) {
	flex-grow: 1;
}

.recommend_icon {
	width: 40px;
	height: 40px;
	border-radius: 50%;
}
