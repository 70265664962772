.card_footer {
	color: white;
	margin: 30px 0;
	width: 100%;
	background-color: var(--grey-scale-8);
	border-radius: 16px;
}

.split {
	border: 1px solid white;
	height: 52px;
}

.launch_button {
	width: 200px;
}

.sub_title {
	margin: 0;
	color: var(--grey-scale-4);
	min-width: 100px;
}

.spacing {
	min-width: 100;
}
