.last_summary_row {
	font-size: 18px;
	font-weight: 600;
	vertical-align: top;
}

.extra_info {
	color: grey;
	font-size: 12px;
	font-weight: normal;
}
