.layout {
	background: linear-gradient(18deg, #ffc6e5 0%, #ffc6e5 26%, #fffbfd 67%, #fffbfd 100%);

	height: 100vh;
	overflow-y: scroll;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.layout > * {
	flex: auto;
}

.logo_container,
.footer_container {
	flex: 0;
}
.logo_container {
	margin: 80px 0 120px 0;
}
.footer_container {
	z-index: 0;
	position: relative;
	width: 100%;
	display: flex;
	justify-content: center;
	padding: 80px 0;
}

.logo {
	width: 140px;
}

.footer_background_container {
	position: absolute;
	bottom: 0;

	height: 100px;
	width: 100%;
}

.footer_container > *,
.layout > * {
	z-index: 1;
}
.footer_container {
	z-index: 0;
}

.footer_logo_container {
	position: absolute;
	content: url('./cari_logo_footer.svg');
	width: auto;
	height: 200px;
	bottom: 0px;
	right: 0px;
	position: absolute;
}
.footer_background_container {
	z-index: 0;
}
.footer_background_container:before {
	content: ' ';
	border-bottom: 800px solid white;
	border-left: 130vw solid transparent;

	bottom: 0px;
	left: -20vw;
	right: 0px;
	z-index: 0;

	position: absolute;
}

/* typographys  */
.title {
	color: var(--gray-900, #101828);
	font-size: 20px;
	font-style: normal;
	font-weight: 500;
	line-height: 30px; /* 150% */
}

.copyright {
	color: var(--grey-scale-5);
	font-size: 12px;
	font-style: normal;
	font-weight: 400;
	line-height: 24px; /* 200% */
}
