.loader_container {
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 999;
}

.overlay {
	width: 100%;
	height: 100%;
	opacity: 0.2;
	background-color: #b9c7c9;
}

.loader {
	height: 20%;
	pointer-events: none;

	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	margin: auto;
}

div.default_spinner {
	pointer-events: none;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-100%, -50%);
}

@media (prefers-reduced-motion: no-preference) {
	.loader {
		animation: loader-spin infinite 6s linear;
	}
}

@keyframes loader-spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}
