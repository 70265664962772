.carousel_wrapper {
	position: relative;
}

.carousel {
	width: 100%;
	display: flex;
	gap: 18px;
	overflow-x: scroll;
	scroll-behavior: smooth;
	scrollbar-width: none; /* hide the scrollbar on firefox */
}

/* hide the scrollbar on chrome */
.carousel::-webkit-scrollbar {
	display: none;
}

.carousel_wrapper .item {
	flex-basis: calc(96% / 3); /* 3 items to show */
	flex-shrink: 0;
	padding: 8px;
}

.carousel_wrapper .carousel_button.prev_button {
	left: -20px;
}

.carousel_wrapper .carousel_button.next_button {
	right: -20px;
}

.carousel_wrapper {
	display: flex;
	align-items: center;
}

.carousel_wrapper > div {
	align-items: center;
}

.carousel_wrapper .carousel_button {
	position: absolute;

	/* button styling */
	z-index: 2;
	background-color: white;

	/* icon center */
	display: flex;
	justify-content: center;
	align-items: center;
}

.carousel_wrapper button.carousel_button svg {
	stroke: var(--ant-primary-color);
	width: 12px;
}

.carousel_wrapper button.carousel_button:disabled {
	background-color: white;
}

.carousel_wrapper button.carousel_button:disabled svg {
	display: flex;
	justify-content: center;
	align-items: center;
}

.carousel_wrapper .prev_button svg,
.carousel_wrapper .next_button svg {
	stroke: var(--ant-primary-color);
}
