.selected_incentive_type {
	background-color: var(--primary-25);
	border: 1px solid var(--primary-600);
}

.incentive_type_card_title {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 12px;
	line-height: 40px;
}

.campaign_time_wrapper {
	padding: 8px 0;
}

.campaign_time_label {
	color: var(--grey-900);
	font-size: 16px;
}

.campaign_time_duration {
	color: var(--grey-900);
	font-size: 20px;
	font-weight: 500;
}

.free_delivery_tag {
	display: flex;
	align-items: center;
	gap: 8px;
	padding: 12px;
	border-radius: 12px;
	margin: 0;
}

.free_delivery_tag_text {
	font-size: 16px;
}

.free_delivery_tag_title {
	font-weight: 700;
}

.form_hidden_input {
	margin: 0 !important;
}
.form_hidden_input :global(.ant-form-item-control) {
	display: none;
}

.main_container {
	height: calc(100% - 40px); /* (100% - page_header_height) */
	overflow-y: auto;
	padding: 0 14px;
}

.column {
	margin-bottom: 24px;
}
