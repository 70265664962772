.recommended_tag {
	width: fit-content;
	padding: 4px 12px;
	border-radius: 4px;
	color: white;
	font-size: 12px;

	display: flex;
	justify-content: center;
	align-items: center;

	gap: 8px;
}

.action_button {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	gap: 8px;
}

.recommendation_icon {
	width: 32px;
	height: 32px;
	border-radius: 50%;
}
