.timeline_item .date {
	color: grey;
	font-size: 12px;
	margin-bottom: 4px;
}

.timeline_item .user {
	margin-bottom: 6px;
}

.last_item_icon {
	font-size: 16px;
}

.empty_data {
	margin-top: 5%;
}
