:root {
	--header-height: 68px;
}

.sidebar {
	grid-area: sidebar;
}

.content {
	grid-area: content;
}

.header {
	grid-area: header;
}

.footer {
	grid-area: footer;
}

.grid_wrapper {
	height: 100vh;
	display: grid;
	grid-gap: 16px;
	grid-template-columns: auto minmax(0, 1fr) minmax(0, 1fr);
	grid-template-rows: var(--header-height) minmax(0, 1fr) /* auto*/;
	grid-template-areas:
		'sidebar header header'
		'sidebar content content';
	/* 'footer  footer  footer'; */

	/* extra */
	padding: 12px;
	background: #f2f4f7;
}
