@media print {
	header {
		display: none;
	}
	html,
	body {
		height: initial !important;
		overflow: initial !important;
		-webkit-print-color-adjust: exact;
		overflow: visible;

		width: auto;
	}

	@page {
		size: A4;
		margin: 0;
	}

	* {
		page-break-inside: avoid;
		page-break-after: avoid;
		page-break-before: avoid;
	}

	table {
		page-break-inside: auto;
	}
	tr {
		page-break-inside: avoid;
		page-break-after: auto;
	}

	.page-break {
		margin-top: 1rem;
		display: block;
		page-break-before: auto;
	}

	footer {
		page-break-after: always;
	}
	h1 {
		break-before: always;
	}
}

.print_preview {
	background: white;
	width: 210mm;
	height: 297mm;
	display: block;
	margin: 0 auto;
	overflow: scroll;
	border: 1px solid black;
	box-shadow: 0 0 0.5cm rgba(0, 0, 0, 0.5);
}

.print_preview[size='A4'] {
	width: 210mm;
	height: 297mm;
}

.print_preview[size='A5'] {
	width: 148.5mm;
	height: 210mm;
}

.print_root_layout {
	padding: 0 40px;
}

.print_root_layout > table {
	width: 100%;
}

.print_header,
.print_header_space {
	height: 120px;
	margin-top: 30px;
}

.print_footer,
.print_footer_space {
	height: 100px;
	margin-bottom: 30px;
}
.print_header {
	position: fixed;
	top: 0;
}
.print_footer {
	position: fixed;
	bottom: 0;
	width: 100%;
	background-color: #ffd1d1;
}
