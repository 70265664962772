.app_header {
	height: 100%;

	display: flex;
	align-items: center;
	justify-content: space-between;

	border-radius: 16px;
	padding: 0 24px;

	user-select: none;
	background: var(--background-color);
}

.avatar {
	background-color: var(--ant-warning-color);
}

/* ** USER DROPDOWN MENU ** */
.info_item {
	user-select: none;
	pointer-events: none;
}

.name {
	font-size: 12px;
}

.email {
	color: grey;
	font-size: 10px;
}

.name,
.email {
	margin-bottom: 0 !important;
}

.logout_btn {
	padding: 12px;
	user-select: none;
	text-align: center;
}
