.tabs_label_container {
	display: flex;
	flex-direction: column;
}

.tabs_label_title {
	display: flex;
	align-items: center;
	gap: 16px;
	margin-bottom: 8px;
	font-size: 20px;
}
:global(.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn) .tabs_label_title {
	color: var(--ant-primary-color);
}

.tabs_label_description {
	color: var(--grey-500);
}

.card_animation {
	max-height: 0;
	transform: scaleY(0);
	transform-origin: 100% 0%;
	overflow: hidden;
	transition-duration: 0.5s;
}

.card_animation.open {
	max-height: fit-content;
	transform: scaleY(1);
}

.radio_option_card > * > * > :global(.ant-card-head-title) {
	padding: 0;
}

.discount_advanced_options_drawer_input {
	width: 50%;
}
